.card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .card {
    width: calc(33.33% - 20px);
    margin-bottom: 20px;
  }

  
  @media (max-width: 992px) {
    .card {
      width: calc(50% - 20px);
    }
  }
  
  @media (max-width: 576px) {
    .card {
      width: calc(100% - 20px);
    }
  }
  
  .service-item a.btn-slide i,
  .service-item a.btn-slide span,
  .price-item a.btn-slide i,
  .price-item a.btn-slide span,
  .team-item div.btn-slide i,
  .team-item div.btn-slide span {
    position: relative;
    height: 40px;
    padding: 0 15px;
    display: inline-flex;
    align-items: center;
    font-size: 16px;
    color: #FFFFFF;
    background:  #d8a603;
    border-radius: 0 35px 35px 0;
    z-index: 2;
    border-color: black;
  }
  
  .team-item div.btn-slide span a i {
    padding: 0 10px;
  }
  
  .team-item div.btn-slide span a:hover i {
    background: #030303;
  }
  
  .service-item a.btn-slide span,
  .price-item a.btn-slide span,
  .team-item div.btn-slide span {
    padding-left: 0;
    left: -100%;
    z-index: 1;
  }
  
  .service-item:hover a.btn-slide i,
  .price-item:hover a.btn-slide i,
  .team-item:hover div.btn-slide i {
    border-radius: 0;
  }
  
  .service-item:hover a.btn-slide span,
  .price-item:hover a.btn-slide span,
  .team-item:hover div.btn-slide span {
    left: 0;
  }
  
  .service-item a.btn-slide:hover i,
  .service-item a.btn-slide:hover span,
  .price-item a.btn-slide:hover i,
  .price-item a.btn-slide:hover span {
    background: #030303;
  }
  