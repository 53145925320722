
.productP-banner {
    position: relative;
    background-image: url('../../../../../public/img/pharam1.jpg');
    background-size: cover;
    background-position: center;
    height: 85vh;
    /* Adjust as needed */
    display: flex;
    align-items: left;
    background-color: rgba(0, 0, 0, 0.5);
}

.productP-content {
    position: relative;
    z-index: 2;
    text-align: left;
    color: white;
    padding: 20px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.productP-content h1 {
    margin-top: 12rem;
    margin-left: 4rem;
    font-size: 4rem;
    color: white;
}


/* Media query for responsiveness */
@media (max-width: 768px) {
    .productP-content h1 {
        font-size: 2.5rem;
    }
}
