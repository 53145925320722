/* SliderComponent.css */
.slider-container {
  width: 100%;
  max-width: 100%;
  margin: auto;
}

.slide {
  position: relative;
  height: 470px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.slide-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  z-index: 1;
}

.slide-content {
  height: 100%;
  position: relative;
  z-index: 2;
  text-align: left;
  color: white;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.5);
}

.slide-content h5 {
  margin-top: 6rem;
  margin-left: 7.5rem;
}

.slide-content h1 {
  margin-left: 7rem;
}

.slide-content p {
  margin-left: 7rem;
  padding-right: 30rem;
}



/* Responsive styles */
@media (max-width: 768px) {

  .slide-background {
    align-items: center;
    background-position: center;
  }

  .slide-content h5 {
    font-size: 18px;
    margin-left: 0;
  }

  .slide-content h1 {
    font-size: 28px;
    margin-top: 1.5rem;
    margin-bottom: 2rem;
    margin-left: 0;
  }

  .slide-content p {
    font-size: 14px;
    padding-right: 2rem;
    margin-left: 0;
  }
}

@media (max-width: 480px) {

  .slide-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    z-index: 1;
  }

  .slide-content h2 {
    font-size: 18px;
  }

  .slide-content h3 {
    font-size: 16px;
  }

  .slide-content p {
    font-size: 12px;
  }
}