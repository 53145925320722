.btn-primary {
    color: white;
    background-color: #d8a603;
    border-color: #d8a603;
}

.btn-primary:hover {
    color:  white;
    background-color: #d8a603;
    border-color: #d8a603
}