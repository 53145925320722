.header-dash{
    background-color: #060315;
    color: #fff;
    padding: 10px;
    text-align: center;
  }

  .header-dash h1{
    color: white;
  }
  
  .dash-nav{
    background-color: #d8a603ca;
    color: #fff;
    padding: 10px;
    text-align: center;
  }
  
  .dash-nav a {
    color: #fff;
    text-decoration: none;
    padding: 10px;
    margin: 0 10px;
    font-weight: bold;
  }
  
  .logout {
    margin-top: 1rem;
    margin-left: 67rem;
    /* border: 2px solid green; */
    background-color: #333;
    color: white;
    font-weight: bold;
    padding: 10px;
    cursor: pointer;
  }