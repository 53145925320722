*,
*::before,
*::after {
    box-sizing: border-box
}


img {
    max-width: 100%;
    border-width: 10%;

}

.gallery {
    background-color: white;



}

.gallery img {
    background-color: #ffffff;
    height: 75%;
    width: 75%;

    cursor: pointer;
    border: 5px solid #d8a603;
}

#gallery-modal .modal-img {
    width: 100%;
    border-width: 15%;
    border-color: #d8a603;

}

.sub-products a:hover {
    font-weight: bold;
}

.midline {
    margin-bottom: 7rem;
    text-align: center;
    font-size: x-large;
    padding-left: 5rem;
    padding-right: 5rem;
}

.service-item,
.price-item,
.team-item {
    box-shadow: 0 0 45px rgba(0, 0, 0, .07);
}

.animated-underline {
    position: relative;
    text-decoration: none;
}

.animated-underline::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 4px;
    background-color: #faab02;
    transform: scale(0);
    transform-origin: left;
    /*transition: transform 0.3s ease-in-out;*/

    transition: width .5s ease, background-color .5s ease;
}

.animated-underline::after {
    transform: scale(1);
}



/* HeroBanner.css */

.product-banner {
    position: relative;
    background-image: url('../../../public/chemicals2.jpg');
    background-size: cover;
    background-position: center;
    height: 85vh;
    /* Adjust as needed */
    display: flex;
    align-items: left;
    background-color: rgba(0, 0, 0, 0.5);
}

.product-content {
    position: relative;
    z-index: 2;
    text-align: left;
    color: white;
    padding: 20px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.product-content h1 {
    margin-top: 12rem;
    margin-left: 4rem;
    font-size: 4rem;
    color: white;
}


/* Media query for responsiveness */
@media (max-width: 768px) {
    .product-content h1 {
        font-size: 2.5rem;
    }
}