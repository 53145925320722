/* NavbarFooter.css */
.popup-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black overlay */
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup-form {
    background-color: #fff; /* White background for the form */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Shadow effect */
    width: 480px; 
}

.popup-form h3 {
    margin-bottom: 20px;
}

.popup-form input {
    width: 100%;
    margin-bottom: 10px;
}

.popup-form button {
    margin-top: 10px;
}

